import Highcharts, { SeriesOptionsType, TooltipFormatterContextObject } from 'highcharts';

import colors from 'styles/theme/colors';

export type ActivitiesPerPatientPerWeekTableRow = {
  categoryName: string;
  activitiesPerPatientPerWeek: { current: number; diff: number | null };
  totalActivities: number;
  totalPatients: number;
};
export type ActivitiesPerPatientPerWeekData = {
  categories: string[];
  values: number[];
  average: number;
  patients: number[];
  activities: number[];
};
// Calculates the scrollable width of the chart based on the number of data points
const calculateWidth = (pointWidth: number, dataLength: number) => {
  return (pointWidth + pointWidth * 1.5) * dataLength;
};

export const generateExtendedChartConfig = (
  { categories, values, average, patients }: ActivitiesPerPatientPerWeekData,
  priorPeriodChartData: { values: number[] }
): Highcharts.Options => ({
  chart: {
    scrollablePlotArea: {
      minWidth: calculateWidth(40, values.length),
      scrollPositionX: 0,
    },
  },
  legend: {
    enabled: true,
    padding: 0,
    itemMarginBottom: 10,
    itemMarginTop: 16,
    align: 'center',
    symbolRadius: 2,
    x: 0,
    verticalAlign: 'bottom',
    y: 0,
    itemStyle: {
      fontSize: '12px',
    },
  },
  xAxis: {
    categories: categories,
    lineWidth: 1,
    lineColor: colors.black25,
    labels: {
      formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
        // This must remain one line.  This "html" string is interpreted by Highcharts and is converted to SVG.
        // If this string is broken into multiple lines, highcharts will sometimes incorrectly render an extra line break in the label.
        return `<span style="color:#0f1226;font-size:10px;">${this.value}</span><br/><span style="color:rgba(15, 18, 38, .5);font-size:8px;">Patients: ${patients[this.pos]}</span>`;
      },
    },
  },
  scrollbar: {
    enabled: true,
  },
  yAxis: {
    gridLineWidth: 1,
    gridLineColor: colors.black10,
    plotLines: [
      {
        color: '#F06532',
        width: 2,
        value: average,
        dashStyle: 'ShortDash',
      },
    ],
  },
  plotOptions: {
    column: {
      maxPointWidth: 40,
      events: { legendItemClick: () => false, mouseOver: () => false },
      dataLabels: {
        enabled: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          return `<span style="font-size:10px;font-weight:normal;">${this.point.label}</span>`;
        },
      },
    },
    line: {
      enableMouseTracking: false,
      events: { legendItemClick: () => false, mouseOver: () => false },
      marker: {
        enabled: false,
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  series: [
    {
      type: 'column',
      data: values.map((val) => ({ y: val, label: val.toFixed(1) })),
      name: 'Activity Per Patient Per Week',
      states: {
        inactive: {
          opacity: 0.05,
        },
      },
    },
    ...(priorPeriodChartData.values.length > 0
      ? [
          {
            type: 'column',
            data: priorPeriodChartData.values.map((val) => ({
              y: +val,
              label: val === null ? 'N/A' : val.toFixed(1),
            })),
            name: 'Prior Activity',
            color: '#C1CBFA',
          } as SeriesOptionsType,
        ]
      : []),
    {
      color: '#F06532',
      name: 'Average',
      type: 'line',
      dashStyle: 'ShortDash',
      data: [average],
    },
  ],
});
