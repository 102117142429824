import styled from 'styled-components';

import AreaChart from 'components/shared/charts/AreaChart';
import { formatDateLabel, formatDuration, getFormattedDatesInRange } from 'components/shared/charts/helpers';
import { Analytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';
import colors from 'styles/theme/colors';
import EscalationCountIcon from 'svg/EscalationCountIcon';

import { DimensionConstants, MetricConstants, RouteConstants, SourceConstants } from '../../constants';
import { getRollup, parseDimensionValues, parseMetricValues } from '../helpers/dataUtils';
import useInsightsQuery from '../helpers/useInsightsQuery';

import MetricTile from './MetricTile';

export default function EscalationResponseTimeTile() {
  const filters = useInsightsStore((state) => state.filters);

  const request = {
    params: {
      source: SourceConstants.ESCALATION_ACKS,
      dimensions: [DimensionConstants.DATE],
      metrics: [MetricConstants.BUSINESS_RESPONSE_TIME, MetricConstants.ID_COUNT],
      rollups: true,
      escalationType: 'escalation',
    },

    processData: (unprocessedData: Analytics) => {
      const [responseTimeAverage, totalEscalations] = getRollup(unprocessedData.data);

      const chartData = getFormattedDatesInRange(filters.startDate as Date, filters.endDate as Date).reduce(
        (acc, formattedDate) => {
          acc[formattedDate] = { responseTime: 0, escalationsForDate: 0 };
          return acc;
        },
        {}
      );

      unprocessedData.data.forEach((row) => {
        const dimensionValues = parseDimensionValues(row) as string[];
        const metricValues = parseMetricValues(row);

        const date = dimensionValues[0];
        const responseTime = metricValues[0];
        const escalationsForDate = metricValues[1];

        if (date) {
          chartData[date] = { responseTime, escalationsForDate };
        }
      });

      return { chartData, responseTimeAverage, totalEscalations };
    },
  };

  const { customRef, loading, query } = useInsightsQuery(request, { onIntersecting: true });

  const { chartData = {}, responseTimeAverage = -1, totalEscalations = 0 } = query.data || {};

  return (
    <MetricTile
      ref={customRef}
      label={'Escalation Response Time'}
      showValue={true}
      loading={loading}
      hasData={
        responseTimeAverage !== -1 &&
        Object.values(chartData || {})
          .map((data: any) => data.responseTime)
          .some((x) => x > 0)
      }
      value={`${formatDuration(responseTimeAverage, true)}`}
      navigateTo={RouteConstants.ESCALATIONS_RESPONSE}>
      <AreaChart
        formatTooltipSupertext={(x) => `${formatDateLabel(x as string)}`}
        formatTooltipSubtext={(y, key) =>
          `<div style="padding-top: 4px;">
            <span style="font-size: 12px;">Average Response Time: ${y ? formatDuration(y, true) : 'N/A'}</span></div>
            <div>
            <span style="font-size: 12px;">Escalations: ${chartData[key].escalationsForDate}</span></div>`
        }
        categories={Object.keys(chartData || {})}
        values={Object.values(chartData || {}).map((data: any) => data.responseTime)}
        config={{
          yAxis: {
            tickAmount: 3,
            labels: {
              formatter: function () {
                return formatDuration(this.value);
              },
            },
          },
          xAxis: {
            labels: {
              formatter: function () {
                return formatDateLabel(this.value as string);
              },
            },
          },
        }}
      />
      <EscalationsCountContainer>
        <Left>
          <EscalationCountIcon />
          <p>Escalations</p>
        </Left>
        <div>
          <p>{totalEscalations}</p>
        </div>
      </EscalationsCountContainer>
    </MetricTile>
  );
}

const EscalationsCountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 8px;
  border: 1px solid ${colors.black10};
  border-radius: 4px;
  padding: 12px 16px;

  div {
    display: flex;

    p {
      font-size: 10px;
      font-weight: bold;
    }
  }
`;

const Left = styled.div`
  p {
    margin-left: 8px;
  }
`;
