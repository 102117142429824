import { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import { useInsightsStore } from 'stores/insightsStore';
import colors from 'styles/theme/colors';
import DiffArrowDown from 'svg/DiffArrowDown';
import DiffArrowUp from 'svg/DiffArrowUp';

export enum Comparator {
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
}

const MetricDiffBadge = ({
  diff,
  comparator,
  formatter = (val) => `${val}`,
}: {
  diff: number | null;
  comparator: Comparator;
  formatter?: (val: number) => string;
}) => {
  const [color, setColor] = useState<string>('');
  const [backgroundColor, setBackgroundColor] = useState<string>('');

  const comparePriorPeriod = useInsightsStore((store) => store.comparePriorPeriod);

  useLayoutEffect(() => {
    if (diff === null || diff === 0) {
      setColor(colors.black50);
      setBackgroundColor(colors.black15);
    } else if (comparator === Comparator.LESS_THAN) {
      setColor(diff < 0 ? '#2EA310' : '#DC2853');
      setBackgroundColor(diff < 0 ? 'rgba(46, 163, 16, .10)' : 'rgba(209, 13, 68, .10)');
    } else if (comparator === Comparator.GREATER_THAN) {
      setColor(diff > 0 ? '#2EA310' : '#DC2853');
      setBackgroundColor(diff > 0 ? 'rgba(46, 163, 16, .10)' : 'rgba(209, 13, 68, .10)');
    }
  }, [comparator, diff]);

  return comparePriorPeriod ? (
    <Container backgroundColor={backgroundColor} color={color}>
      {(() => {
        switch (diff) {
          case null:
            return <>&mdash;</>;
          case 0:
            return <>{formatter(diff)}</>;
          default:
            return (
              <>
                <Arrow>{diff > 0 ? <DiffArrowUp fill={color} /> : <DiffArrowDown fill={color} />}</Arrow>
                {formatter(Math.abs(diff))}
              </>
            );
        }
      })()}
    </Container>
  ) : null;
};

const Container = styled.div<{ backgroundColor: string; color: string }>`
  height: 16px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  padding: 0 4px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  margin-left: 12px;
  display: flex;
  align-items: center;
`;

const Arrow = styled.div`
  margin-right: 4px;
  display: flex;
  align-items: center;
`;

export default MetricDiffBadge;
