import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  DimensionConstants,
  MetricConstants,
  RouteConstants,
  SortOrder,
  SourceConstants,
} from 'components/insights/constants';
import ChartContainer from 'components/insights/layout/details/ChartContainer';
import { generateExtendedChartConfig } from 'components/insights/layout/details/helpers/responseTimeUtils';
import MetricDetailContainer from 'components/insights/layout/details/MetricDetailContainer';
import MetricDetailHeader from 'components/insights/layout/details/MetricDetailHeader';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import {
  groupByDateIndex,
  mapPreviousDataToCurrentCategories,
  parseDimensionValues,
  parseMetricValues,
} from 'components/insights/layout/helpers/dataUtils';
import { formatDuration } from 'components/shared/charts/helpers';
import Histogram from 'components/shared/charts/Histogram';
import Toggle from 'components/shared/Toggle';
import { Flags } from 'constants/flags';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import { OWNER_CLIENT_TYPES } from 'models/Client';
import { EscalationTypes } from 'models/Escalation';
import { Analytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';
import { BodySmall } from 'styles/typography';

import useInsightsQuery from '../../helpers/useInsightsQuery';
import ComparePriorPeriodToggle from '../ComparePriorPeriodToggle';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

import ResponseTimeDataTable from './ResponseTimeDataTable';

type ResponseTimeDetailProps = {
  escalationType: EscalationTypes;
};

export type ResponseTimeChartData = {
  categories: string[];
  responseTime: number[];
  averageResponseTime: number;
  count: number[];
};

const ResponseTimeDetail = ({ escalationType }: ResponseTimeDetailProps) => {
  const { profile } = useProfile();
  const isMobile = useIsMobile();
  const comparePriorPeriod = useInsightsStore((state) => state.comparePriorPeriod);
  const isPriorityNote = escalationType === EscalationTypes.PriorityNote;

  const [businessHoursOnly, setBusinessHoursOnly] = useState(true);
  const [selectedDimension, setSelectedDimension] = useState<DimensionConstants>(
    profile.isAcute && isPriorityNote
      ? DimensionConstants.PROVIDER_CLIENT
      : isPriorityNote
        ? DimensionConstants.PROVIDER_NAME
        : DimensionConstants.OWNER_GROUP_NAME
  );
  const responseTimeLabel = `${isPriorityNote ? 'Priority Note' : 'Escalation'} Response Time`;
  const countLabel = isPriorityNote ? 'Priorities' : 'Escalations';
  const navigate = useNavigate();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const metrics = useMemo(
    () => [
      ...(businessHoursOnly ? [MetricConstants.BUSINESS_RESPONSE_TIME] : [MetricConstants.RESPONSE_TIME]),
      MetricConstants.ID_COUNT,
    ],
    [businessHoursOnly]
  );

  const request = useMemo(
    () => ({
      params: {
        dimensions: [selectedDimension],
        metrics,
        source: SourceConstants.ESCALATION_ACKS,
        sortBy: `${MetricConstants.ID_COUNT} ${SortOrder.DESC}, ${DimensionConstants.GROUP_NAME} ${SortOrder.DESC}`,
        rollups: true,
        escalationType: isPriorityNote ? 'priority_note' : 'escalation',
      },
      processData: (unprocessedData: Analytics) => {
        const groupedByDateIndex = groupByDateIndex(unprocessedData);

        if (!groupedByDateIndex.length) {
          return null;
        }

        const parsedData = groupedByDateIndex.map((row) => {
          return row.reduce(
            (acc, row) => {
              const dimensionValues = parseDimensionValues(row) as string[];
              const metricValues = parseMetricValues(row);
              const acuteGroupName = dimensionValues[0];
              const count = metricValues[1];
              const time = metricValues[0];
              if (row.grouping === 1) {
                acc.totalRecords = count;
                acc.averageResponseTime = time;
              } else {
                acc.categories.push(acuteGroupName || 'None');
                acc.responseTime.push(time);
                acc.count.push(count);
              }

              return acc;
            },
            {
              categories: [],
              responseTime: [],
              count: [],
              averageResponseTime: 0,
              totalRecords: 0,
            } as {
              categories: string[];
              responseTime: number[];
              count: number[];
              averageResponseTime: number;
              totalRecords: number;
            }
          );
        });
        const { totalRecords, ...chartData } = parsedData[0];

        // It is possible that this is undefined
        const priorPeriod = parsedData[1];

        const priorPeriodValues = comparePriorPeriod
          ? mapPreviousDataToCurrentCategories('responseTime', chartData, priorPeriod)
          : [];

        return {
          chartData,
          totalRecords,
          priorPeriodChartData: {
            responseTime: priorPeriodValues,
            averageResponseTime: priorPeriod?.averageResponseTime ?? null, // 0 is a valid value
          },
        };
      },
    }),
    [selectedDimension, metrics, isPriorityNote, comparePriorPeriod]
  );

  const { loading, query } = useInsightsQuery(request);

  const {
    chartData = { categories: [], responseTime: [], averageResponseTime: 0, count: [] },
    totalRecords = 0,
    priorPeriodChartData = { responseTime: [], averageResponseTime: null },
  } = query.data || {};

  const chartConfig = useMemo(
    () => generateExtendedChartConfig(chartData, priorPeriodChartData),
    [chartData, priorPeriodChartData]
  );

  const displayHeaders = useMemo(
    () => ({
      [OWNER_CLIENT_TYPES.PAYOR]: 'Payer',
      [OWNER_CLIENT_TYPES.HEALTH_SYSTEM]: 'Hospital',
      [OWNER_CLIENT_TYPES.PHYSICIAN_GROUP]: 'Physician Group',
    }),
    []
  );

  const responseTimeViewBySelectOptions = {
    label: displayHeaders[profile.clientType],
    value: DimensionConstants.OWNER_GROUP_NAME,
  };
  const providerNameOption = {
    label: `${selectedGroupType?.displayName}`,
    value: DimensionConstants.PROVIDER_NAME,
  };
  const priorityNoteViewBySelectOptions = [
    providerNameOption,
    {
      label: `${selectedGroupType?.displayName} Company`,
      value: DimensionConstants.PROVIDER_CLIENT,
    },
  ];

  return (
    <>
      <Toggles>
        {profile.hasFlag(Flags.Insights) && (
          <>
            <ComparePriorPeriodToggle />
            <Separator height={'20px'} />
          </>
        )}
        <ToggleContainer>
          <Toggle active={businessHoursOnly} onChange={(val) => setBusinessHoursOnly(val)} disabled={false} />
          <BodySmall>Business Hours Only</BodySmall>
        </ToggleContainer>
      </Toggles>
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.responseTime.length}
        header={
          <>
            <MetricDetailHeader
              label={responseTimeLabel}
              hasData={!!chartData.responseTime.length}
              loading={loading}
              value={
                <>
                  {formatDuration(chartData.averageResponseTime, true)}
                  <MetricDiffBadge
                    diff={
                      priorPeriodChartData.averageResponseTime !== null
                        ? chartData.averageResponseTime - priorPeriodChartData.averageResponseTime
                        : null
                    }
                    comparator={Comparator.LESS_THAN}
                    formatter={(val) => `${formatDuration(val, true)}`}
                  />
                </>
              }
            />
            <Separator />
            <MetricDetailHeader
              label={countLabel}
              loading={loading}
              value={totalRecords.toString()}
              hasData={!!chartData.responseTime.length}
            />
            {!isMobile && (
              <ViewBySelect
                selectedDimension={selectedDimension}
                groupType={selectedGroupType}
                additionalOptions={isPriorityNote ? priorityNoteViewBySelectOptions : [responseTimeViewBySelectOptions]}
                excludeGroupTypeOptions
                onChange={(selectedOption) => {
                  setSelectedDimension(selectedOption.value);
                }}></ViewBySelect>
            )}
          </>
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <ResponseTimeDataTable
        chartData={chartData}
        priorPeriodChartData={priorPeriodChartData}
        selectedDimension={selectedDimension}
        totalRecords={totalRecords}
        loading={loading}
        isPriorityNote={isPriorityNote}
        responseTimeLabel={responseTimeLabel}
        countLabel={countLabel}
        displayHeaders={displayHeaders}
      />
    </>
  );
};

export default ResponseTimeDetail;
const Separator = styled.div<{ height?: string }>`
  height: ${({ height }) => height || '100%'};
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black15};
  margin: 0 24px;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 24px;

  ${BodySmall} {
    font-size: 12px;
  }
`;

const Toggles = styled.div`
  display: flex;
`;
