import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { DimensionConstants } from 'components/insights/constants';
import Card from 'components/insights/layout/Card';
import {
  ActivitiesPerPatientPerWeekData,
  ActivitiesPerPatientPerWeekTableRow,
} from 'components/insights/layout/details/helpers/activitiesPerPatientPerWeekUtils';
import LocalExport from 'components/insights/layout/details/LocalExport';
import TableContainer from 'components/insights/layout/details/TableContainer';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import DataTable from 'components/shared/DataTable';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

type Props = {
  chartData: ActivitiesPerPatientPerWeekData;
  priorPeriodChartData: { values: number[]; average: number };
  selectedDimension: string;
  totalActivities: number;
  totalPatients: number;
  loading: boolean;
};

const ActivitiesPerPatientWeekDataTable = ({
  chartData,
  priorPeriodChartData,
  selectedDimension,
  totalActivities,
  totalPatients,
  loading,
}: Props) => {
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const tableData = useMemo<ActivitiesPerPatientPerWeekTableRow[]>(() => {
    const { categories, values, patients, activities } = chartData;
    const priorActivities = priorPeriodChartData.values;

    return categories.map((categoryName, i) => ({
      categoryName,
      activitiesPerPatientPerWeek: {
        current: values[i],
        diff: priorActivities[i] !== null ? values[i] - priorActivities[i] : null,
      },
      totalPatients: patients[i],
      totalActivities: activities[i],
      priorActivitiesPerPatientPerWeek: priorActivities[i],
    }));
  }, [chartData, priorPeriodChartData.values]);

  const columnHelper = createColumnHelper<ActivitiesPerPatientPerWeekTableRow>();

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case DimensionConstants.PLAN_TYPE:
        return 'Plan Type';
      case DimensionConstants.EPISODE_TYPE:
        return 'Episode Type';
      case DimensionConstants.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      default:
        return selectedGroupType?.displayName;
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  const columns = useMemo<ColumnDef<ActivitiesPerPatientPerWeekTableRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('activitiesPerPatientPerWeek', {
        header: 'Activities Per Patient Per Week',
        cell: (data) => (
          <>
            <p>{`${data.getValue().current.toFixed(1)}`}</p>{' '}
            <MetricDiffBadge
              diff={data.getValue().diff}
              comparator={Comparator.GREATER_THAN}
              formatter={(val) => `${val.toFixed(1)}`}
            />
          </>
        ),
        footer: () => `${chartData.average.toFixed(1)} (Avg)`,
      }),
      columnHelper.accessor('totalActivities', {
        header: 'Activities',
        cell: (data) => `${data.getValue()}`,
        footer: () => `${totalActivities} (Total)`,
      }),
      columnHelper.accessor('totalPatients', {
        header: 'Patients',
        cell: (data) => `${data.getValue()}`,
        footer: () => `${totalPatients} (Total)`,
      }),
    ],
    [chartData.average, columnHelper, getTableHeader, totalActivities, totalPatients]
  );
  const desktopOnlyColumns = ['totalActivities', 'totalPatients'];

  const defaultSort = [
    {
      id: 'totalPatients',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableTitleContainer>
        <Card.Title>Activities Per Patient Per Week Overview</Card.Title>
        <LocalExport
          columns={columns}
          data={tableData}
          loading={loading}
          filePrefix='activities-per-patient-per-week'
        />
      </TableTitleContainer>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        desktopOnlyColumns={desktopOnlyColumns}
        loading={loading}
      />
    </TableContainer>
  );
};

export default ActivitiesPerPatientWeekDataTable;
