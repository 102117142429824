import { differenceInCalendarDays, differenceInDays, format, parseISO, subDays } from 'date-fns';
/**
 *
 * @param date
 * @returns formatted Date string in the form of `yyyy-MM-dd`
 */
export function simpleDashDate(date?: string | Date | null) {
  const pdate = parseDate(date);
  if (!pdate) return null;
  if (isNaN(pdate.getTime())) return null;
  return format(pdate, 'yyyy-MM-dd');
}
/**
 *
 * @param date
 * @returns formatted Date string in the form of `MM/dd/yyyy`
 */
export function simpleDate(date?: string | Date | null) {
  const pdate = parseDate(date);
  if (!pdate) return null;
  if (isNaN(pdate.getTime())) return null;
  return format(pdate, 'MM/dd/yyyy');
}

/**
 * Formats to a date string with time
 * @param date
 * @returns A string containing the date and time. Example: "04/10/2024 at 12:00 AM"
 */
export const simpleDateAtTime = (date: string | Date) => {
  const pdate = parseDate(date);
  if (!pdate) return null;
  if (isNaN(pdate.getTime())) return null;

  return format(pdate, "MM/dd/yyyy 'at' h:mm a");
};

/**
 * @param date
 * @returns formatted Date string depending on the relative difference between the date and the current date
 */
export function dateAtTime(date?: string | Date | null) {
  const pdate = parseDate(date);
  if (!pdate) return null;
  if (isNaN(pdate.getTime())) return null;
  const reldif = differenceInCalendarDays(pdate, new Date());
  if (reldif == 0) {
    return format(pdate, "'Today at' h:mm a");
  }
  if (reldif == 1) {
    return format(pdate, "'Tomorrow at' h:mm a");
  }
  if (reldif == -1) {
    return format(pdate, "'Yesterday at' h:mm a");
  }
  return format(pdate, "MM/dd/yyyy 'at' h:mm a");
}
/**
 *
 * @param date
 * @returns formatted Date string in the form of `MM/dd/yyyy 'at' h:mm a`
 */
export function dateAtTimeNoRelative(date?: string | Date | null) {
  const pdate = parseDate(date);
  if (!pdate) return null;
  if (isNaN(pdate.getTime())) return null;
  return format(pdate, "MM/dd/yyyy 'at' h:mm a");
}

export function dateWithoutTimezone(date?: string | Date | null) {
  return simpleDate(date);
  //need more information about what the difference is supposed to be,
  //since neither function printed a timezone.
}
/**
 * @param date
 * @returns formatted Date string in the form of `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`
 */
export function parseDate(date?: string | Date | null) {
  if (!date) return null;
  if (date instanceof Date) return date;
  try {
    const parsed = parseISO(date);
    if (isNaN(parsed.getTime())) return null;
    return parsed;
  } catch {
    return null;
  }
}

/**
 * @param start
 * @param end
 * @returns object with start and end dates for the prior period
 */
export function calculatePriorPeriod(start: Date, end: Date) {
  const priorEnd = subDays(start, 1); // one day before start of current period
  const priorStart = subDays(priorEnd, differenceInDays(end, start)); // same difference in days as current period

  return {
    start: priorStart,
    end: priorEnd,
  };
}
