import Highcharts, { SeriesOptionsType, TooltipFormatterContextObject } from 'highcharts';

// https://www.highcharts.com/forum/viewtopic.php?t=48229 for reference
Highcharts.seriesType('column2', 'column', {});

import colors from 'styles/theme/colors';

export type ChartData = {
  categories: string[];
  dischargeCounts: number[];
  readmissionCounts: number[];
  readmissionRates: number[];
};

// Calculates the scrollable width of the chart based on the number of data points
const calculateWidth = (pointWidth: number, dataLength: number) => {
  return (pointWidth + pointWidth * 1.5) * dataLength;
};

const getDischargesData = (dischargeCounts: number[], readmissionRates: number[]) => {
  return dischargeCounts.map((value, i) => {
    return {
      y: +value, // Converts the null value to 0 so it gets plotted
      label: value === null ? 'N/A' : `${readmissionRates[i].toFixed(1)}%`,
      dataLabels: {
        enabled: true,
        formatter: function (this: TooltipFormatterContextObject): string {
          return `<span style="font-size:10px;font-weight:normal;">${this.point.label}</span>`;
        },
      },
    };
  });
};

export const generateExtendedChartConfig = (
  { categories, dischargeCounts, readmissionCounts, readmissionRates }: ChartData,
  priorPeriodChartData: {
    readmissionRates: [];
    readmissionCounts: [];
    totalDischarges: number;
    averageRate: number;
    dischargeCounts: number[];
  }
): Highcharts.Options => ({
  chart: {
    scrollablePlotArea: {
      minWidth: calculateWidth(40, categories.length),
      scrollPositionX: 0,
    },
  },
  legend: {
    enabled: true,
    padding: 0,
    itemMarginBottom: 10,
    itemMarginTop: 16,
    align: 'center',
    symbolRadius: 2,
    x: 0,
    verticalAlign: 'bottom',
    y: 0,
    itemStyle: {
      fontSize: '12px',
    },
  },
  xAxis: [
    {
      categories: categories,
      lineWidth: 1,
      lineColor: colors.black25,
      labels: {
        style: {
          fontSize: '10px',
          color: colors.black,
        },
      },
    },
    {
      categories: categories,
      lineWidth: 1,
      visible: false,
    },
  ],
  scrollbar: {
    enabled: true,
  },
  yAxis: {
    gridLineWidth: 1,
    gridLineColor: colors.black10,
  },
  plotOptions: {
    column: {
      maxPointWidth: 40,
      events: { legendItemClick: () => false, mouseOver: () => false },
    },
    // @ts-expect-error column2 does not exist on the plotOptions type, but is custom to this chart.  See above link for example by highcharts dev
    column2: {
      maxPointWidth: 40,
      events: { legendItemClick: () => false, mouseOver: () => false },
    },
  },
  tooltip: {
    enabled: false,
  },
  series: [
    {
      name: 'Discharges',
      color: colors.black15,
      type: 'column',
      yAxis: 0,
      data: getDischargesData(dischargeCounts, readmissionRates),
    },
    {
      name: 'Readmissions',
      color: '#DC2853',
      type: 'column2' as 'column',
      yAxis: 0,
      data: readmissionCounts,
    },

    ...(priorPeriodChartData.readmissionCounts.length > 0
      ? [
          {
            type: 'column',
            data: getDischargesData(priorPeriodChartData.dischargeCounts, priorPeriodChartData.readmissionRates),
            showInLegend: false,
            color: colors.black15,
          } as SeriesOptionsType,
          {
            type: 'column2' as 'column',
            data: priorPeriodChartData.readmissionCounts,
            name: 'Prior Readmissions',
            color: '#E8ADBE',
          } as SeriesOptionsType,
        ]
      : []),
  ],
});
