import { useMemo } from 'react';
import styled from 'styled-components';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import DataTable from 'components/shared/DataTable';
import { simpleDate } from 'lib/date';
import { Analytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import { DimensionConstants, SortOrder, SourceConstants } from '../../../constants';
import Card from '../../Card';
import { parseDimensionValues } from '../../helpers/dataUtils';
import useAnalyticsInfiniteQuery from '../../helpers/useAnalyticsInfiniteQuery';
import ApiExport from '../ApiExport';
import TableContainer from '../TableContainer';

type ReadmittedPatientsTableRow = {
  patientName: string;
  episodeUrl: string;
  readmissionDate: string;
  lengthOfStay: number;
};

const ReadmittedPatientsDataTable = () => {
  const startDate = useInsightsStore((state) => state.filters.startDate);
  const endDate = useInsightsStore((state) => state.filters.endDate);

  const readmittedPatientsQueryParams = useMemo(
    () => ({
      source: SourceConstants.LOCATION_EPISODE_DISCHARGES,
      dimensions: [
        DimensionConstants.PATIENT_NAME,
        DimensionConstants.EPISODE_URL,
        DimensionConstants.READMISSION_DATE,
        DimensionConstants.LENGTH_OF_STAY,
      ],
      metrics: [],
      sortBy: `${DimensionConstants.LENGTH_OF_STAY} ${SortOrder.DESC}`,
      pageSize: 10,
    }),
    []
  );

  const analyticResponse = useAnalyticsInfiniteQuery(
    readmittedPatientsQueryParams,
    {
      initialPageParam: readmittedPatientsQueryParams,
      getNextPageParam: (lastPage: Analytics) => lastPage?.links?.next?.payload,
    },
    false
  );

  const data =
    analyticResponse.data?.pages
      .flatMap((page) => page?.data || [])
      .map((row) => {
        const dimensionValues = parseDimensionValues(row) as string[];

        const patientName = dimensionValues[0];
        const episodeUrl = dimensionValues[1];
        const readmissionDate = dimensionValues[2];
        const lengthOfStay = Number(dimensionValues[3]);

        return {
          patientName,
          episodeUrl,
          readmissionDate,
          lengthOfStay,
        };
      }) || [];

  const columnHelper = useMemo(() => createColumnHelper<ReadmittedPatientsTableRow>(), []);
  const columns = useMemo<ColumnDef<ReadmittedPatientsTableRow, any>[]>(
    () => [
      columnHelper.accessor('patientName', {
        header: 'Patient Name',
        cell: (data) => (
          <LabelBold>
            <Link target='_blank' href={data.row.original.episodeUrl}>
              {data.getValue()}
            </Link>
          </LabelBold>
        ),
      }),
      columnHelper.accessor('readmissionDate', {
        header: 'Readmission Date',
        cell: (data) => simpleDate(data.getValue()),
      }),
      columnHelper.accessor('lengthOfStay', {
        header: 'Length of Stay',
        cell: (data) => `${data.getValue()} days`,
      }),
    ],
    [columnHelper]
  );

  const desktopOnlyColumns = ['readmissionDate', 'lengthOfStay'];

  const defaultSort = [
    {
      id: 'lengthOfStay',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableTitleContainer>
        <Card.Title>
          Readmitted Patients ({simpleDate(startDate as Date)} - {simpleDate(endDate as Date)})
        </Card.Title>
        <ApiExport
          disabled={!data.length}
          show={!analyticResponse.isLoading}
          params={readmittedPatientsQueryParams}
          filename='readmitted-patients-export'
        />
      </TableTitleContainer>
      <DataTable
        columns={columns}
        data={data}
        defaultSortBy={defaultSort}
        desktopOnlyColumns={desktopOnlyColumns}
        hasMore={analyticResponse.hasNextPage}
        loading={analyticResponse.isLoading || analyticResponse.isFetchingNextPage}
        onBodyEndReached={analyticResponse.fetchNextPage}
      />
    </TableContainer>
  );
};

export default ReadmittedPatientsDataTable;

const Link = styled.a`
  color: var(--primary-blue);
  cursor: pointer;
  text-decoration: none;
`;
