import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useInsightsContext } from 'components/insights/InsightsContainer';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import Histogram from 'components/shared/charts/Histogram';
import { Flags } from 'constants/flags';
import useIsMobile from 'hooks/useIsMobile';
import { Analytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';

import { DimensionConstants, MetricConstants, RouteConstants, SortOrder, SourceConstants } from '../../../constants';
import {
  groupByDateIndex,
  mapPreviousDataToCurrentCategories,
  parseDimensionValues,
  parseMetricValues,
} from '../../helpers/dataUtils';
import useInsightsQuery from '../../helpers/useInsightsQuery';
import ChartContainer from '../ChartContainer';
import ComparePriorPeriodToggle from '../ComparePriorPeriodToggle';
import { generateExtendedChartConfig } from '../helpers/totalDischargesUtils';
import MetricDetailContainer from '../MetricDetailContainer';
import MetricDetailHeader from '../MetricDetailHeader';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

import TotalDischargesDataTable from './TotalDischargesDataTable';

const TotalDischargesDetail = () => {
  const { profile } = useInsightsContext();
  const comparePriorPeriod = useInsightsStore((state) => state.comparePriorPeriod);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const [selectedDimension, setSelectedDimension] = useState<DimensionConstants>(
    profile.isAcute ? DimensionConstants.PROVIDER_CLIENT : DimensionConstants.GROUP_NAME
  );

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DISCHARGES,
        dimensions: [selectedDimension],
        metrics: [MetricConstants.ID_COUNT],
        sortBy: `${MetricConstants.ID_COUNT} ${SortOrder.DESC}, ${DimensionConstants.GROUP_NAME} ${SortOrder.DESC}`,
        rollups: true,
      },
      processData: (unprocessedData: Analytics) => {
        const groupedByDateIndex = groupByDateIndex(unprocessedData);

        if (!groupedByDateIndex.length) {
          return null;
        }

        const parsedData = groupedByDateIndex.map((row) => {
          return row.reduce(
            (acc, row) => {
              const dimensionValues = parseDimensionValues(row) as string[];
              const metricValues = parseMetricValues(row);

              const groupName = dimensionValues[0];
              const discharges = metricValues[0];

              if (!groupName && row.grouping === 1) {
                acc.totalDischarges = discharges;
              } else {
                acc.categories.push(groupName || 'None');
                acc.values.push(discharges);
              }

              return acc;
            },
            { categories: [], values: [], totalDischarges: 0 } as {
              categories: string[];
              values: number[];
              totalDischarges: number;
            }
          );
        });
        const { totalDischarges, ...chartData } = parsedData[0];

        // Could be undefined if there is no prior period data
        const priorPeriod = parsedData[1];

        const priorPeriodValues = comparePriorPeriod
          ? mapPreviousDataToCurrentCategories('values', chartData, priorPeriod)
          : [];

        return {
          chartData,
          totalDischarges,
          priorTotalDischarges: priorPeriod?.totalDischarges ?? null, // 0 is a valid value
          priorPeriodChartData: { values: priorPeriodValues },
        };
      },
    }),
    [selectedDimension, comparePriorPeriod]
  );

  const { loading, query } = useInsightsQuery(request);

  const {
    chartData = { categories: [], values: [] },
    totalDischarges = 0,
    priorTotalDischarges = null,
    priorPeriodChartData = { values: [] },
  } = query.data || {};

  const chartConfig = useMemo(
    () => generateExtendedChartConfig(chartData, priorPeriodChartData),
    [chartData, priorPeriodChartData]
  );

  const getValueString = () => {
    if (!totalDischarges) return;

    return totalDischarges.toString();
  };

  return (
    <>
      {profile.hasFlag(Flags.Insights) && <ComparePriorPeriodToggle />}
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.values.length}
        header={
          <>
            <MetricDetailHeader
              label='Total Discharges'
              loading={loading}
              hasData={!!chartData.values.length}
              value={
                <>
                  {getValueString()}
                  <MetricDiffBadge
                    diff={priorTotalDischarges !== null ? totalDischarges - priorTotalDischarges : null}
                    comparator={Comparator.GREATER_THAN}
                    formatter={(val) => `${val.toFixed()}`}
                  />
                </>
              }
            />
            {!isMobile && (
              <ViewBySelect
                selectedDimension={selectedDimension}
                additionalOptions={[
                  {
                    label: 'Disposition',
                    value: DimensionConstants.REASON,
                  },
                ]}
                groupType={selectedGroupType}
                onChange={(selectedOption) => {
                  setSelectedDimension(selectedOption.value);
                }}></ViewBySelect>
            )}
          </>
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <TotalDischargesDataTable
        chartData={chartData}
        priorPeriodChartData={priorPeriodChartData}
        selectedDimension={selectedDimension}
        totalDischarges={totalDischarges}
        loading={loading}
      />
    </>
  );
};

export default TotalDischargesDetail;
