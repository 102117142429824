import { useMemo } from 'react';

import { MetricConstants, RouteConstants, SourceConstants } from 'components/insights/constants';
import FullGauge from 'components/shared/charts/FullGauge';
import { Analytics } from 'services/api/insights/analytics';
import colors from 'styles/theme/colors';

import { parseMetricValues } from '../helpers/dataUtils';
import useInsightsQuery from '../helpers/useInsightsQuery';

import MetricTile from './MetricTile';

export default function ReadmissionRateTile() {
  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DISCHARGES,
        metrics: [MetricConstants.ID_COUNT, MetricConstants.READMISSIONS_SUM, MetricConstants.READMISSION_RATE],
      },
      processData: (unprocessedData: Analytics) => {
        if (!unprocessedData.data.length) {
          return { dischargesCount: 0, readmissionsCount: 0, readmissionsRate: -1 };
        }
        const [dischargesCount, readmissionsCount, readmissionsRate] = parseMetricValues(unprocessedData.data[0]);
        return { dischargesCount, readmissionsCount, readmissionsRate };
      },
    }),
    []
  );

  const { customRef, loading, query } = useInsightsQuery(request, { onIntersecting: true });

  const { readmissionsCount = 0, dischargesCount = 0, readmissionsRate = -1 } = query.data || {};

  const legend = [
    { name: 'Readmissions', value: readmissionsCount, color: '#DC2853' },
    { name: 'Discharges', value: dischargesCount, color: colors.black10 },
  ];

  return (
    <MetricTile
      ref={customRef}
      data-gtm-id='insightsReadmissionRate'
      data-cy='insightsReadmissionRate'
      label='All Cause Readmission Rate'
      showValue={false}
      loading={loading}
      hasData={readmissionsRate !== -1}
      navigateTo={RouteConstants.READMISSION_RATE}>
      <FullGauge
        minValue={0}
        maxValue={dischargesCount}
        value={readmissionsCount}
        colorStops={[[0, '#DC2853']]}
        legend={legend}
        label={`${readmissionsRate.toFixed(1)}%`}
      />
    </MetricTile>
  );
}
