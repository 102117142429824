import { ColumnDef } from '@tanstack/react-table';

import { AlosTableRow } from 'components/insights/layout/details/averageLengthOfStay/AverageLengthOfStayDataTable';
import { ActivitiesPerPatientPerWeekTableRow } from 'components/insights/layout/details/helpers/activitiesPerPatientPerWeekUtils';
import { CategoryResponseTimeRow, CategoryRow } from 'components/insights/layout/details/helpers/utils';
import { OesTableRow } from 'components/insights/layout/details/olioEngagementScore/OlioEngagementScoreDataTable';
import { ReadmissionRateTableRow } from 'components/insights/layout/details/readmissionRate/ReadmissionRateDataTable';

import Export from './Export';

type exportColumns =
  | ColumnDef<CategoryRow, any>[]
  | ColumnDef<AlosTableRow, any>[]
  | ColumnDef<OesTableRow, any>[]
  | ColumnDef<ReadmissionRateTableRow, any>[]
  | ColumnDef<ActivitiesPerPatientPerWeekTableRow, any>[]
  | ColumnDef<CategoryResponseTimeRow, any>[];

type Props = {
  columns: exportColumns;
  data: CategoryRow[];
  loading: boolean;
  filePrefix: string;
};

function generateCSV(columns: exportColumns, data: CategoryRow[]) {
  let csv = '';
  columns.forEach((column, index) => {
    csv += column.header + (index === columns.length - 1 ? '\n' : ',');
  });
  data.forEach((row) => {
    columns.forEach((column, index) => {
      let val;
      if (typeof row[column.accessorKey] === 'object') {
        val = row[column.accessorKey].current;
      } else {
        val = row[column.accessorKey];
      }
      csv += val + (index === columns.length - 1 ? '\n' : ',');
    });
  });
  return csv;
}

function downloadCSV(csv: string, filePrefix: string) {
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filePrefix}-export-${new Date().toISOString().split('T')[0]}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function LocalExport({ columns, data, loading, filePrefix }: Props) {
  const handleClick = () => {
    if (data.length > 0) {
      downloadCSV(generateCSV(columns, data), filePrefix);
    }
  };

  return <Export disabled={!data.length} show={!loading} onClick={handleClick} />;
}

export default LocalExport;
