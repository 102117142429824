import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useProfile } from 'context/profile';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import usePrevious from 'hooks/usePrevious';
import isDeepEqual from 'lib/isDeepEqual';
import { Analytics, retrieveAnalytics } from 'services/api/insights/analytics';
import { getAnalyticsFilters, useInsightsStore } from 'stores/insightsStore';

type Request<Result> = {
  params: any;
  processData: (data: Analytics) => Result;
};

type DataFetchConfig = {
  onIntersecting?: boolean;
};

export default function useInsightsQuery<Result = Record<string, any>>(
  request: Request<Result>,
  config: DataFetchConfig = {}
) {
  const { onIntersecting = false } = config;

  const { pathname } = useLocation();
  const isInsightsDashboard = !!pathname.match(/\/insights$/);

  const { profile } = useProfile();
  const queryClient = useQueryClient();
  const comparePriorPeriod = useInsightsStore((state) => state.comparePriorPeriod);
  const analyticsFilters = useInsightsStore((state) => getAnalyticsFilters(state, profile));
  const filters = useInsightsStore((state) => state.filters);
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const previousFilters = usePrevious(filters);
  const previousSelectedGroupType = usePrevious(selectedGroupType);

  const isDataCached = queryClient.getQueryData(['analytics', request, analyticsFilters]);

  const filtersChanged =
    !isDeepEqual(previousFilters, filters) || !isDeepEqual(previousSelectedGroupType, selectedGroupType);

  const {
    isIntersecting: hasIntersected,
    setIsIntersecting: setHasIntersected,
    customRef,
  } = useIntersectionObserver({
    initialState: !onIntersecting,
    once: true,
    threshold: 0.2,
  });

  const query = useQuery({
    queryKey: ['analytics', request, analyticsFilters],
    queryFn: () => retrieveAnalytics(request.params, analyticsFilters),
    // (1) Don't let the query fire immediately when filters change - let the useEffect block below
    // handle resetting the intersection observer, which will then trigger the query if/when the
    // element is actually in view.
    // (2) Don't refetch data when toggling off Compare Prior Period if drill-in page already has
    // cached values for selected filters.
    // Changing filters, navigating away, or refreshing will always refetch, at least in the background.
    enabled:
      !filtersChanged &&
      (!onIntersecting || hasIntersected) &&
      (isInsightsDashboard || comparePriorPeriod || !isDataCached),
    select: (unprocessedData) => {
      try {
        return request.processData(unprocessedData);
      } catch (error) {
        console.error('Error parsing analytics data', error);
      }
    },
  });

  useEffect(() => {
    if (filtersChanged) {
      setHasIntersected(false);
    }
  }, [filtersChanged, setHasIntersected]);

  return { customRef, loading: query.isPending || query.isRefetching, query };
}
