import { useEffect } from 'react';
import styled from 'styled-components';

import Toggle from 'components/shared/Toggle';
import useIsMobile from 'hooks/useIsMobile';
import { calculatePriorPeriod, simpleDate } from 'lib/date';
import { useInsightsActions, useInsightsStore } from 'stores/insightsStore';
import { colors } from 'styles/theme/colors';
import { BodySmall } from 'styles/typography';

type ComparePriorPeriodToggleProps = {
  selectedLegendColor?: string;
  priorLegendColor?: string;
};

const ComparePriorPeriodToggle = ({
  selectedLegendColor = colors.primaryBlue,
  priorLegendColor = '#C1CBFA',
}: ComparePriorPeriodToggleProps) => {
  const { setComparePriorPeriod } = useInsightsActions();
  const isMobile = useIsMobile();
  const comparePriorPeriod = useInsightsStore((state) => state.comparePriorPeriod);
  const filtersStartDate = useInsightsStore((state) => state.filters.startDate);
  const filtersEndDate = useInsightsStore((state) => state.filters.endDate);

  // Sets comparePriorPeriod to false when component unmounts
  useEffect(
    () => () => {
      setComparePriorPeriod(false);
    },
    [setComparePriorPeriod]
  );

  return (
    <ToggleContainer>
      <Toggle active={comparePriorPeriod} onChange={setComparePriorPeriod} disabled={false} />
      <>
        <BodySmall>Compare Prior Period</BodySmall>
        {comparePriorPeriod && !isMobile && (
          <>
            <Range>
              <LegendDot color={selectedLegendColor} />
              <BodySmaller>
                Selected ({simpleDate(filtersStartDate as Date)} - {simpleDate(filtersEndDate as Date)})
              </BodySmaller>
            </Range>
            <Range>
              <LegendDot color={priorLegendColor} />
              <BodySmaller>
                Prior ({simpleDate(calculatePriorPeriod(filtersStartDate as Date, filtersEndDate as Date).start)} -{' '}
                {simpleDate(calculatePriorPeriod(filtersStartDate as Date, filtersEndDate as Date).end)})
              </BodySmaller>
            </Range>
          </>
        )}
      </>
    </ToggleContainer>
  );
};

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 24px;

  ${BodySmall} {
    font-size: 12px;
  }
`;

const BodySmaller = styled(BodySmall)`
  font-size: 8px;
`;

const LegendDot = styled.div<{ color: string }>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const Range = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export default ComparePriorPeriodToggle;
