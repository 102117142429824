import { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import ActivitiesPerPatientWeekDetail from 'components/insights/layout/details/activitiesPerPatientWeek/ActivitiesPerPatientWeekDetail';
import Page from 'components/layouts/page/Page';
import FilterChips from 'components/shared/FilterChips';
import { FilterSection } from 'components/shared/filters/FilterSlideout';
import DesktopOnly from 'components/shared/responsiveness/DesktopOnly';
import { EscalationTypes } from 'models/Escalation';
import { useInsightsActions, useInsightsStore } from 'stores/insightsStore';
import { FilterOption } from 'stores/types';

import AverageLengthOfStayDetail from './layout/details/averageLengthOfStay/AverageLengthOfStayDetail';
import OlioEngagementScoreDetail from './layout/details/olioEngagementScore/OlioEngagementScoreDetail';
import ReadmissionRateDetail from './layout/details/readmissionRate/ReadmissionRateDetail';
import ResponseTimeDetail from './layout/details/responseTime/ResponseTimeDetail';
import TotalDischargesDetail from './layout/details/totalDischarges/TotalDischargesDetail';
import { getFiltersForSelectedGroupType } from './layout/helpers/filters';
import MetricsDashboard from './layout/MetricsDashboard';
import { RouteConstants } from './constants';
import { useInsightsContext } from './InsightsContainer';
import InsightsFilterBar from './InsightsFilterBar';

export default function InsightsPage() {
  const { profile } = useInsightsContext();
  const filters = useInsightsStore((state) => state.filters);
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const { setSelectedFilters } = useInsightsActions();

  const [filtersForSelectedGroupType, filterSections] = useMemo(
    () => getFiltersForSelectedGroupType(filters, selectedGroupType, profile.actingClient.clientType),
    [filters, selectedGroupType, profile.actingClient.clientType]
  );

  const chippableFilters = Object.entries(filtersForSelectedGroupType).reduce((acc, [filterKey, values]) => {
    if (Array.isArray(values) && values.length) {
      return { ...acc, [filterKey]: values };
    }
    return acc;
  }, {});

  return (
    <Page>
      <StyledPageHeader>
        <InsightsFilterBar
          filterSections={filterSections as FilterSection[]}
          activeFilters={filtersForSelectedGroupType}
        />
      </StyledPageHeader>
      <Page.SubHeader>
        <DesktopOnly>
          <FilterChips
            filters={chippableFilters}
            onRemoveFilter={(filterKey, id) => {
              const newFilters = {
                ...filtersForSelectedGroupType,
                [filterKey]: (filtersForSelectedGroupType[filterKey] as FilterOption[]).filter(
                  (filter) => filter.id !== id
                ),
              };

              setSelectedFilters(newFilters);
            }}
          />
        </DesktopOnly>
      </Page.SubHeader>
      <Page.Content>
        <Routes>
          <Route path='/' element={<MetricsDashboard />} />
          <Route path={RouteConstants.OLIO_ENGAGEMENT_SCORE} element={<OlioEngagementScoreDetail />} />
          <Route path={RouteConstants.READMISSION_RATE} element={<ReadmissionRateDetail />} />
          <Route path={RouteConstants.AVERAGE_LENGTH_OF_STAY} element={<AverageLengthOfStayDetail />} />
          <Route path={RouteConstants.TOTAL_DISCHARGES} element={<TotalDischargesDetail />} />
          <Route path={RouteConstants.ACTIVITIES_PER_PATIENT_PER_WEEK} element={<ActivitiesPerPatientWeekDetail />} />
          <Route
            path={RouteConstants.PRIORITIES_NOTE_RESPONSE}
            element={<ResponseTimeDetail escalationType={EscalationTypes.PriorityNote} />}
          />
          <Route
            path={RouteConstants.ESCALATIONS_RESPONSE}
            element={<ResponseTimeDetail escalationType={EscalationTypes.Escalation} />}
          />
          <Route path='*' element={<Navigate to={RouteConstants.INSIGHTS_BASE} />} />
        </Routes>
      </Page.Content>
    </Page>
  );
}

const StyledPageHeader = styled(Page.Header)`
  background: none;
  border-bottom: none;
`;
