import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { DimensionConstants } from 'components/insights/constants';
import Card from 'components/insights/layout/Card';
import LocalExport from 'components/insights/layout/details/LocalExport';
import TableContainer from 'components/insights/layout/details/TableContainer';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import { formatDuration } from 'components/shared/charts/helpers';
import DataTable from 'components/shared/DataTable';
import { useProfile } from 'context/profile';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import { CategoryResponseTimeRow } from '../helpers/utils';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

import { ResponseTimeChartData } from './ResponseTimeDetail';

type Props = {
  chartData: ResponseTimeChartData;
  priorPeriodChartData: { responseTime: number[]; averageResponseTime: number };
  selectedDimension: string;
  totalRecords: number;
  loading: boolean;
  isPriorityNote: boolean;
  responseTimeLabel: string;
  countLabel: string;
  displayHeaders: { [key: string]: string };
};

const ResponseTimeDataTable = ({
  chartData,
  priorPeriodChartData,
  selectedDimension,
  totalRecords,
  loading,
  isPriorityNote,
  responseTimeLabel,
  countLabel,
  displayHeaders,
}: Props) => {
  const { profile } = useProfile();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const tableData = useMemo<CategoryResponseTimeRow[]>(() => {
    const categories = chartData.categories;
    const responseTime = chartData.responseTime;
    const count = chartData.count;
    return categories.map((categoryName, i) => ({
      categoryName,
      responseTime: {
        current: responseTime[i],
        diff:
          priorPeriodChartData.responseTime[i] !== null ? responseTime[i] - priorPeriodChartData.responseTime[i] : null,
      },
      count: count[i],
    }));
  }, [chartData, priorPeriodChartData]);

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case DimensionConstants.PLAN_TYPE:
        return 'Plan Type';
      case DimensionConstants.EPISODE_TYPE:
        return 'Episode Type';
      case DimensionConstants.PROVIDER_CLIENT:
        return isPriorityNote ? `${selectedGroupType?.displayName} Company` : displayHeaders[profile.clientType];
      default:
        return isPriorityNote ? selectedGroupType?.displayName : displayHeaders[profile.clientType];
    }
  }, [displayHeaders, isPriorityNote, profile.clientType, selectedDimension, selectedGroupType?.displayName]);

  const columnHelper = createColumnHelper<CategoryResponseTimeRow>();
  const columns = useMemo<ColumnDef<CategoryResponseTimeRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('responseTime', {
        header: responseTimeLabel,
        cell: (data) => (
          <>
            <p>{`${formatDuration(data.getValue().current, true)}`}</p>{' '}
            <MetricDiffBadge
              diff={priorPeriodChartData.averageResponseTime !== null ? data.getValue().diff : null}
              comparator={Comparator.LESS_THAN}
              formatter={(val) => `${formatDuration(val, true)}`}
            />
          </>
        ),
        footer: () => `${formatDuration(chartData.averageResponseTime, true)} (Avg)`,
      }),
      columnHelper.accessor('count', {
        header: countLabel,
        footer: () => `${totalRecords} (Total)`,
      }),
    ],
    [
      columnHelper,
      getTableHeader,
      responseTimeLabel,
      countLabel,
      priorPeriodChartData.averageResponseTime,
      chartData.averageResponseTime,
      totalRecords,
    ]
  );

  const defaultSort = [
    {
      id: 'count',
      desc: true,
    },
  ];

  const desktopOnlyColumns = ['count'];

  const filePrefix = isPriorityNote ? 'priority-response-time' : 'escalation-response-time';

  return (
    <TableContainer>
      <TableTitleContainer>
        <Card.Title>{responseTimeLabel} Overview</Card.Title>
        <LocalExport columns={columns} data={tableData} loading={loading} filePrefix={filePrefix} />
      </TableTitleContainer>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        loading={loading}
        desktopOnlyColumns={desktopOnlyColumns}
      />
    </TableContainer>
  );
};

export default ResponseTimeDataTable;
