import { useEffect } from 'react';

import { DischargeSteps } from 'components/dischargeForm/helpers';
import ActivityInput from 'components/shared/activityInput/ActivityInput';
import useActivityInput, { ActivityInputValues } from 'components/shared/activityInput/useActivityInput';
import Alert from 'components/shared/Alert';
import Checkbox from 'components/shared/form/Checkbox';
import DatePicker from 'components/shared/form/DatePicker';
import FieldLabel from 'components/shared/form/FieldLabel';
import { Select } from 'components/shared/form/InfiniteScrollDropdown';
import Input from 'components/shared/form/Input';
import InputGroup from 'components/shared/form/InputGroup';
import { useForm } from 'context/form';
import DischargeReason from 'models/DischargeReason';
import Group from 'models/Group';
import LocationEpisode from 'models/LocationEpisode';
import { useInfiniteDischargeReasons } from 'services/api/dischargeReasons';
import { useInfiniteGroups } from 'services/api/group';
import { colors } from 'styles/theme/colors';

type Props = {
  locationEpisode: LocationEpisode;
  showActualDischargeDate?: boolean;
};

export type DischargeFormValues = {
  dischargeReason: DischargeReason;
  dischargedGroup: Group;
  dischargedLocationOther: string;
  againstMedicalAdvice: boolean;
  actualDischargeDate: Date;
  note: ActivityInputValues;
  questions: Record<string, string>;
};

export type DischargeFormMeta = {
  step: DischargeSteps;
  isSubmitting: boolean;
  isUploadingAttachments: boolean;
};

export default function DischargeInformation({ locationEpisode, showActualDischargeDate = false }: Props) {
  const { values, setValue, setMeta, errors, validateField } = useForm<DischargeFormValues, DischargeFormMeta>();
  const { note, setNote } = useActivityInput({ ...values.note });

  const asyncDischargeReasons = useInfiniteDischargeReasons({
    include: 'dischargedToGroupType',
    sortBy: 'order asc',
    groupType: locationEpisode.group?.groupTypeId,
  });

  const selectedReason = values.dischargeReason;

  const asyncGroupOptions = useInfiniteGroups({
    ownerId: selectedReason?.dischargedToGroupType?.isManager ? undefined : locationEpisode.owner.id,
    groupType: selectedReason?.dischargedToGroupTypeId ?? undefined,
    'id.not': locationEpisode.rehabInformation?.latestRehabFacility?.id,
    search: '',
    sortBy: 'name asc',
    viewOnly: true,
  });

  const isOtherLocation = values.dischargedGroup?.name === 'Other';
  const enteredAt = locationEpisode.currentRehabState.enteredAt;
  const isReadmission = selectedReason?.isReadmission;
  const notesLabel = isReadmission ? 'Readmission Details' : 'Notes (optional)';
  const selectedReasonInfoMessage = selectedReason?.infoMessage;
  const selectedReasonWarningMessage = selectedReason?.warningMessage;

  useEffect(() => {
    setValue('note', note);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  return (
    <>
      {showActualDischargeDate && (
        <InputGroup title='Actual Discharge Date' error={errors.actualDischargeDate}>
          <DatePicker
            minDate={enteredAt ?? new Date()}
            maxDate={new Date()}
            selected={values.actualDischargeDate ?? null}
            onChange={(z) => {
              setValue('actualDischargeDate', z);
            }}
            $hasError={!!errors.actualDischargeDate}
            onBlur={() => validateField('actualDischargeDate', values)}
          />
        </InputGroup>
      )}
      <InputGroup title={`Select discharge disposition`} error={errors.dischargeReason}>
        <Select<DischargeReason>
          {...asyncDischargeReasons}
          placeholder='Select discharge disposition'
          onChange={(change) => {
            setValue('questions', null, { validate: false });
            setValue('dischargedGroup', null, { validate: false });
            setValue('dischargedLocationOther', null, { validate: false });
            setValue('dischargeReason', change);
          }}
          getOptionValue={(prop) => prop.id}
          getOptionLabel={(prop) => prop.displayName}
          value={values.dischargeReason}
        />
        {selectedReasonInfoMessage && (
          <Alert style={{ marginTop: 10 }}>
            <Alert.Text>{selectedReasonInfoMessage}</Alert.Text>
          </Alert>
        )}
      </InputGroup>
      <InputGroup>
        <Checkbox
          checked={values.againstMedicalAdvice || false}
          checkedColor={colors.primaryBlue}
          size={16}
          labelSize='14px'
          label='Discharged against medical advice (AMA)'
          onChange={(val) => setValue('againstMedicalAdvice', val)}
        />
      </InputGroup>
      <InputGroup
        title={selectedReason?.dischargedToGroupType?.displayName}
        visible={!!selectedReason?.dischargedToGroupTypeId}
        error={errors.dischargedGroup}>
        <Select
          {...asyncGroupOptions}
          appendedOptions={[new Group({ name: 'Other' })]}
          placeholder='Select a location'
          getOptionValue={(prop) => prop.id}
          getOptionLabel={(prop) => prop.name}
          onChange={(change) => {
            setValue('questions', null, { validate: false });
            setValue('dischargedLocationOther', null, { validate: false });
            setValue('dischargedGroup', change);
          }}
          value={values.dischargedGroup}
        />
      </InputGroup>
      <InputGroup title='Other' visible={isOtherLocation} error={errors.dischargedLocationOther}>
        <Input
          value={values.dischargedLocationOther ?? ''}
          onChange={(event) => setValue('dischargedLocationOther', event.target.value)}
          $hasError={false}
          disabled={false}
        />
      </InputGroup>
      <FieldLabel>{notesLabel}</FieldLabel>
      <ActivityInput
        locationEpisodeId={locationEpisode.id}
        values={note}
        setValues={setNote}
        onUploading={(uploading) => setMeta('isUploadingAttachments', uploading)}
      />
      {selectedReasonWarningMessage && (
        <Alert style={{ marginTop: 10 }}>
          <Alert.Text>{selectedReasonWarningMessage}</Alert.Text>
        </Alert>
      )}
    </>
  );
}
