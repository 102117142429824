import AreaChart from 'components/shared/charts/AreaChart';
import { formatDateLabel, getFormattedDatesInRange } from 'components/shared/charts/helpers';
import { Analytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';

import { DimensionConstants, MetricConstants, RouteConstants, SourceConstants } from '../../constants';
import { getRollup, parseDimensionValues, parseMetricValues } from '../helpers/dataUtils';
import useInsightsQuery from '../helpers/useInsightsQuery';

import MetricTile from './MetricTile';

export default function TotalDischargesTile() {
  const filters = useInsightsStore((state) => state.filters);

  const request = {
    params: {
      source: SourceConstants.LOCATION_EPISODE_DISCHARGES,
      dimensions: [DimensionConstants.DATE],
      metrics: [MetricConstants.ID_COUNT],
      rollups: true,
    },
    processData: (unprocessedData: Analytics) => {
      const [totalDischarges] = getRollup(unprocessedData.data);

      const dateRange = getFormattedDatesInRange(filters.startDate as Date, filters.endDate as Date).reduce(
        (acc, formattedDate) => {
          acc[formattedDate] = 0;
          return acc;
        },
        {}
      );

      unprocessedData.data.forEach((row) => {
        const dimensionValues = parseDimensionValues(row) as string[];
        const metricValues = parseMetricValues(row);

        const date = dimensionValues[0];
        const dischargesCount = metricValues[0];

        if (date) {
          dateRange[date] = dischargesCount;
        }
      });

      return {
        categories: Object.keys(dateRange).map(formatDateLabel),
        values: Object.values(dateRange),
        totalDischarges,
      };
    },
  };

  const { customRef, loading, query } = useInsightsQuery(request, { onIntersecting: true });

  const { categories = [], values = [], totalDischarges = -1 } = query.data || {};

  return (
    <MetricTile
      ref={customRef}
      data-gtm-id='insightsTotalDischarges'
      data-cy='insightsTotalDischarges'
      label='Total Discharges'
      showValue
      loading={loading}
      hasData={totalDischarges !== -1 && values.some((value) => value)}
      value={`${totalDischarges}`}
      navigateTo={RouteConstants.TOTAL_DISCHARGES}>
      <AreaChart
        formatTooltipSupertext={(x) => `${x}`}
        formatTooltipSubtext={(y) => `Total Discharges: ${y}`}
        categories={categories}
        values={values as number[]}
      />
    </MetricTile>
  );
}
