import { Analytics } from 'services/api/insights/analytics';

type DataValues = { value: number | string | null }[];

export type DataRow = {
  dimensionValues: DataValues | [];
  metricValues: DataValues;
  grouping?: number;
  datesIndex: number;
};

export const parseDimensionValues = ({ dimensionValues }: DataRow, asNumber = false) =>
  dimensionValues.map(({ value }) => (asNumber ? Number(value ?? -1) : value));

export const parseMetricValues = ({ metricValues }: DataRow) => metricValues.map(({ value }) => Number(value ?? -1));

export const getRollupRow = (data: DataRow[]) => {
  const result = data.reduce(
    (accumulator, row) => ((accumulator.grouping ?? -1) > (row.grouping ?? -1) ? accumulator : row),
    {} as DataRow
  );

  return result.grouping ? result : null;
};

export const getRollup = (data: DataRow[], { dataParser = parseMetricValues } = {}) => {
  const rollup = getRollupRow(data);

  return rollup ? dataParser(rollup) : [];
};

export const getNonRollups = (data: DataRow[]) => {
  const rollup = getRollupRow(data);

  if (!rollup) {
    return data;
  }

  return data.filter((row: DataRow) => (row.grouping ?? -1) < (rollup?.grouping ?? -1));
};

export const mapPreviousDataToCurrentCategories = (propertyName: string, currentData: any, previousData: any) => {
  return currentData.categories.map((category) => {
    if (previousData === undefined) return null;
    const previousIndex = previousData.categories.indexOf(category);
    return previousIndex === -1 ? null : previousData[propertyName][previousIndex];
  });
};

export const groupByDateIndex = (unprocessedData: Analytics) => {
  const { data } = unprocessedData;

  if (!data.length) {
    return [];
  }

  const maxDateIndex = Math.max(...data.map((row) => row.datesIndex));

  const groupedByDateIndex = new Array(maxDateIndex + 1).fill([]);

  data.forEach((row) => {
    groupedByDateIndex[row.datesIndex] = [...groupedByDateIndex[row.datesIndex], row];
  });

  return groupedByDateIndex;
};
