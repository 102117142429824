import { useMemo } from 'react';

import { DimensionConstants, MetricConstants, RouteConstants, SourceConstants } from 'components/insights/constants';
import { parseDimensionValues, parseMetricValues } from 'components/insights/layout/helpers/dataUtils';
import MetricTile from 'components/insights/layout/tiles/MetricTile';
import Histogram from 'components/shared/charts/Histogram';
import { Analytics } from 'services/api/insights/analytics';

import useInsightsQuery from '../helpers/useInsightsQuery';

type LosCountsRow = {
  los: number;
  count: number;
};

export default function AverageLengthOfStayTile() {
  const categories = useMemo(
    () => ['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80-89', '90+'],
    []
  );

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DISCHARGES,
        dimensions: [DimensionConstants.LENGTH_OF_STAY],
        metrics: [MetricConstants.ID_COUNT, MetricConstants.AVERAGE_LENGTH_OF_STAY],
        sortBy: DimensionConstants.LENGTH_OF_STAY,
        rollups: true,
      },
      processData: (unprocessedData: Analytics) => {
        const rawData = unprocessedData.data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, true) as number[];
            const metricValues = parseMetricValues(row);

            const los = dimensionValues[0];

            // rollup dimension value of null has been coalesced to -1 here
            if (los < 0) {
              acc.alos = metricValues[1];
            } else {
              acc.losCounts.push({ los, count: metricValues[0] });
            }

            return acc;
          },
          { alos: -1, losCounts: [] } as { alos: number; losCounts: LosCountsRow[] }
        );

        const { alos, losCounts } = rawData;

        const histogramData = categories.map((category) =>
          losCounts.reduce((acc, row) => {
            const [min, max = null] = category.split('-').map((value) => parseInt(value, 10));

            const include = max ? row.los >= min && row.los <= max : row.los >= min;

            return include ? acc + row.count : acc;
          }, 0)
        );

        return { alos, histogramData };
      },
    }),
    [categories]
  );

  const { customRef, loading, query } = useInsightsQuery(request, { onIntersecting: true });

  const { histogramData = [], alos = -1 } = query.data || {};

  const config: Highcharts.Options = useMemo(
    () => ({
      series: [
        {
          type: 'column',
          data: histogramData,
        },
      ],
      xAxis: {
        categories,
      },
    }),
    [categories, histogramData]
  );

  const value = alos !== -1 ? `${alos.toFixed(1)} days` : '';

  return (
    <MetricTile
      ref={customRef}
      data-gtm-id='insightsALOS'
      data-cy='insightsALOS'
      label='Average Length of Stay'
      loading={loading}
      hasData={alos !== -1 && !!histogramData.length}
      value={value}
      navigateTo={RouteConstants.AVERAGE_LENGTH_OF_STAY}>
      <Histogram
        config={config}
        formatTooltipSupertext={(x) => `${x} Days`}
        formatTooltipSubtext={(y) => `Total Patients: ${y}`}
      />
    </MetricTile>
  );
}
