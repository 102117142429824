import { useMemo } from 'react';

import OlioSolidGauge from 'components/shared/charts/OlioSolidGauge';
import { Analytics } from 'services/api/insights/analytics';

import { MetricConstants, RouteConstants, SourceConstants } from '../../constants';
import { parseMetricValues } from '../helpers/dataUtils';
import useInsightsQuery from '../helpers/useInsightsQuery';

import MetricTile from './MetricTile';

export default function OlioEngagementScoreTile() {
  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DAYS,
        metrics: [MetricConstants.OLIO_ENGAGEMENT_SCORE],
      },
      processData: (unprocessedData: Analytics) => {
        const [oesRollup] = unprocessedData.data.length ? parseMetricValues(unprocessedData.data[0]) : [-1];

        return { olioEngagementScore: oesRollup };
      },
    }),
    []
  );

  const { customRef, loading, query } = useInsightsQuery(request, { onIntersecting: true });

  const { olioEngagementScore = -1 } = query.data || {};

  return (
    <MetricTile
      ref={customRef}
      data-gtm-id='insightsOES'
      data-cy='insightsOES'
      label='Olio Engagement Score (OES)'
      tooltipText='Measurement of engagement activity in Olio'
      showValue={false}
      loading={loading}
      hasData={olioEngagementScore !== -1}
      navigateTo={RouteConstants.OLIO_ENGAGEMENT_SCORE}>
      <OlioSolidGauge value={olioEngagementScore} label={`${olioEngagementScore}%`} subLabel='OES Goal 100%' />
    </MetricTile>
  );
}
