import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useInsightsContext } from 'components/insights/InsightsContainer';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import useInsightsQuery from 'components/insights/layout/helpers/useInsightsQuery';
import Histogram from 'components/shared/charts/Histogram';
import { Flags } from 'constants/flags';
import useIsMobile from 'hooks/useIsMobile';
import { Analytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';

import { DimensionConstants, MetricConstants, RouteConstants, SortOrder, SourceConstants } from '../../../constants';
import {
  groupByDateIndex,
  mapPreviousDataToCurrentCategories,
  parseDimensionValues,
  parseMetricValues,
} from '../../helpers/dataUtils';
import ChartContainer from '../ChartContainer';
import ComparePriorPeriodToggle from '../ComparePriorPeriodToggle';
import { generateExtendedChartConfig } from '../helpers/oesUtils';
import MetricDetailContainer from '../MetricDetailContainer';
import MetricDetailHeader from '../MetricDetailHeader';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

import OlioEngagementScoreDataTable from './OlioEngagementScoreDataTable';

const OlioEngagementScoreDetail = () => {
  const { profile } = useInsightsContext();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const comparePriorPeriod = useInsightsStore((state) => state.comparePriorPeriod);

  const [selectedDimension, setSelectedDimension] = useState<DimensionConstants>(
    profile.isAcute ? DimensionConstants.PROVIDER_CLIENT : DimensionConstants.GROUP_NAME
  );

  const oesRequest = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DAYS,
        dimensions: [selectedDimension],
        metrics: [MetricConstants.OLIO_ENGAGEMENT_SCORE, MetricConstants.LOCATION_EPISODE_ID_COUNT],
        sortBy: `${MetricConstants.OLIO_ENGAGEMENT_SCORE} ${SortOrder.DESC}`,
        rollups: true,
      },
      processData: (unprocessedData: Analytics) => {
        const groupedByDateIndex = groupByDateIndex(unprocessedData);

        if (!groupedByDateIndex.length) {
          return null;
        }

        const parsedData = groupedByDateIndex.map((row) => {
          return row.reduce(
            (acc, row) => {
              const dimensionValues = parseDimensionValues(row) as string[];
              const metricValues = parseMetricValues(row);

              const groupName = dimensionValues[0];
              const score = metricValues[0];
              const locationEpisodeCount = metricValues[1];

              if (row.grouping === 1) {
                acc.averageOes = score;
              } else {
                acc.categories.push(groupName || 'None');
                acc.scores.push(score);
                acc.locationEpisodeCounts.push(locationEpisodeCount);
                acc.sumLocationEpisodes += locationEpisodeCount;
              }

              return acc;
            },
            { categories: [], locationEpisodeCounts: [], scores: [], sumLocationEpisodes: 0, averageOes: -1 } as {
              categories: string[];
              locationEpisodeCounts: number[];
              scores: number[];
              sumLocationEpisodes: number;
              averageOes: number;
            }
          );
        });

        const { averageOes } = parsedData[0];

        // It is possible that this is undefined
        const priorPeriod = parsedData[1];

        const priorPeriodValues = comparePriorPeriod
          ? mapPreviousDataToCurrentCategories('scores', parsedData[0], priorPeriod)
          : [];

        const priorOverallScore = priorPeriod?.scores.length ? priorPeriod.averageOes : null;

        return {
          ...parsedData[0],
          overallScore: averageOes,
          priorPeriodChartData: { scores: priorPeriodValues, overallScore: priorOverallScore },
        };
      },
    }),
    [selectedDimension, comparePriorPeriod]
  );

  const { loading, query } = useInsightsQuery(oesRequest);

  const {
    categories = [],
    scores = [],
    locationEpisodeCounts = [],
    sumLocationEpisodes = 0,
    overallScore = 0,
    priorPeriodChartData = { scores: [], overallScore: null },
  } = query.data || {};

  const chartConfig = useMemo(
    () => generateExtendedChartConfig({ categories, values: scores }, { values: priorPeriodChartData.scores }),
    [categories, scores, priorPeriodChartData]
  );

  return (
    <>
      {profile.hasFlag(Flags.Insights) && <ComparePriorPeriodToggle />}
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!categories.length}
        header={
          <>
            <MetricDetailHeader
              label='Olio Engagement Score (OES)'
              hasData={!!categories.length}
              loading={loading}
              value={
                <>
                  {`${overallScore}%`}
                  <MetricDiffBadge
                    diff={
                      priorPeriodChartData.overallScore !== null
                        ? +overallScore.toFixed() - +priorPeriodChartData.overallScore.toFixed()
                        : null
                    }
                    comparator={Comparator.GREATER_THAN}
                    formatter={(val) => `${val.toFixed()}%`}
                  />
                </>
              }
              tooltipText='Measurement of engagement activity in Olio'
            />
            {!isMobile && (
              <ViewBySelect
                selectedDimension={selectedDimension}
                groupType={selectedGroupType}
                onChange={(selectedOption) => {
                  setSelectedDimension(selectedOption.value);
                }}></ViewBySelect>
            )}
          </>
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <OlioEngagementScoreDataTable
        categories={categories}
        scores={scores}
        locationEpisodeCounts={locationEpisodeCounts}
        sumLocationEpisodes={sumLocationEpisodes}
        overallScore={overallScore}
        priorPeriodScores={priorPeriodChartData.scores}
        loading={loading}
        selectedDimension={selectedDimension}
      />
    </>
  );
};

export default OlioEngagementScoreDetail;
