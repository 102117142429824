export default function DiffArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='6' height='5' viewBox='0 0 6 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.282364 1.29516C0.164173 1.1297 0.282454 0.899853 0.485797 0.899853H5.5142C5.71755 0.899853 5.83583 1.1297 5.71764 1.29516L3.20343 4.81505C3.10373 4.95463 2.89627 4.95463 2.79657 4.81505L0.282364 1.29516Z'
        fill={props.fill}
      />
    </svg>
  );
}
