import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import LocalExport from 'components/insights/layout/details/LocalExport';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import DataTable from 'components/shared/DataTable';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import { DimensionConstants } from '../../../constants';
import Card from '../../Card';
import { CategoryRow } from '../helpers/utils';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import TableContainer from '../TableContainer';

export type OesTableRow = CategoryRow & {
  score: { current: number; diff: number | null };
};

type Props = {
  selectedDimension: DimensionConstants;
  sumLocationEpisodes: number;
  overallScore: number;
  categories: string[];
  scores: number[];
  priorPeriodScores: number[];
  locationEpisodeCounts: number[];
  loading: boolean;
};

const OlioEngagementScoreDataTable = ({
  selectedDimension,
  sumLocationEpisodes,
  overallScore,
  categories,
  scores,
  priorPeriodScores,
  locationEpisodeCounts,
  loading,
}: Props) => {
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const tableData = useMemo<OesTableRow[]>(
    () =>
      categories.map((categoryName, i) => ({
        categoryName,
        score: {
          current: scores[i] ?? 0,
          diff: priorPeriodScores[i] !== null ? scores[i] - priorPeriodScores[i] : null,
        },
        locationEpisodes: locationEpisodeCounts[i] ?? 0,
      })),
    [locationEpisodeCounts, categories, scores, priorPeriodScores]
  );

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case DimensionConstants.PLAN_TYPE:
        return 'Plan Type';
      case DimensionConstants.EPISODE_TYPE:
        return 'Episode Type';
      case DimensionConstants.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      default:
        return selectedGroupType?.displayName;
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  const columnHelper = createColumnHelper<OesTableRow>();

  const columns = useMemo<ColumnDef<OesTableRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('locationEpisodes', {
        header: 'Patients',
        footer: () => `${sumLocationEpisodes} (Total)`,
      }),
      columnHelper.accessor('score', {
        header: 'Olio Engagement Score (OES)',
        cell: (data) => (
          <>
            <p>{`${data.getValue().current}%`}</p>{' '}
            <MetricDiffBadge
              diff={data.getValue().diff}
              comparator={Comparator.GREATER_THAN}
              formatter={(val) => `${val.toFixed()}%`}
            />
          </>
        ),
        footer: () => `${overallScore}% (Avg)`,
      }),
    ],
    [columnHelper, getTableHeader, sumLocationEpisodes, overallScore]
  );

  const desktopOnlyColumns = ['locationEpisodes'];

  const defaultSort = [
    {
      id: 'score',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableTitleContainer>
        <Card.Title>Olio Engagement Score (OES) Overview</Card.Title>
        <LocalExport columns={columns} data={tableData} loading={loading} filePrefix='olio-engagement-score' />
      </TableTitleContainer>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        desktopOnlyColumns={desktopOnlyColumns}
        loading={loading}
      />
    </TableContainer>
  );
};

export default OlioEngagementScoreDataTable;
