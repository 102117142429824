import { useEffect } from 'react';
import { createContext, useContext } from 'react';

import InsightsPage from 'components/insights/InsightsPage';
import { useProfile } from 'context/profile';
import Profile from 'models/Profile';
import { useInsightsActions, useInsightsStore } from 'stores/insightsStore';

type InsightsContextType = {
  profile: Profile;
};

export const InsightsContext = createContext<InsightsContextType>({
  profile: {} as Profile,
});

export const useInsightsContext = () => useContext(InsightsContext);

const InsightsContainer = () => {
  const { profile } = useProfile();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const { resetFilters } = useInsightsActions();

  useEffect(() => {
    resetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.actingClient.id]);

  return (
    <InsightsContext.Provider value={{ profile }}>{selectedGroupType?.id && <InsightsPage />}</InsightsContext.Provider>
  );
};

export default InsightsContainer;
