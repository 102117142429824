export default function DiffArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='6' height='5' viewBox='0 0 6 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.282364 3.80469C0.164173 3.97016 0.282454 4.2 0.485797 4.2H5.5142C5.71755 4.2 5.83583 3.97016 5.71764 3.80469L3.20343 0.284807C3.10373 0.145219 2.89627 0.145219 2.79657 0.284807L0.282364 3.80469Z'
        fill={props.fill}
      />
    </svg>
  );
}
